@import "./variables";
@import "./functions";

.min-width-1280 {
  min-width: 1280px;
}

.min-width-760 {
  min-width: 760px;
}

.content-align-center {
  display: flex;
  align-items: center;
}

.dark-blue-bg {
  background-color: $deep_ocean;
}

.pt-40 {
  padding-top: 40px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-80 {
  padding-bottom: 80px;
}

.padding-bottom-3 {
  padding-bottom: 3rem;
}

.mt-0 {
  margin-top: 0;
}

.mt-0_3 {
  margin-top: 0.325rem;
}

.mt-0_5 {
  margin-top: 0.5rem;
}

.mt-0_6 {
  margin-top: 0.625rem;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-0_5 {
  margin-bottom: 0.5rem;
}

.ml-0 {
  margin-left: 0;
}

.ml-0_6 {
  margin-left: 0.625rem;
}

.mr-0_5 {
  margin-right: 0.5rem;
}

.p-col-6_5 {
  width: 55%
}

.p-col-5_5 {
  width: 45%
}

.fw-6 {
  font-weight: 600;
}

.no-result-msg {
  margin-top: 1.5em;
  display: flex;
  align-items: center;
}

.full-height-minus-header {
  min-height: calc(100vh - 60px) //60px is the height of the header
}

.sidebar-button-height {
  height: 2.5rem;
}

%link-text {
  text-decoration: underline;
  cursor: pointer;
  color: $forest;
  @extend .larger-text-light-1;

  &.no-underline{
    text-decoration: none!important;
  }
}

.no-result-text {
  color: $forest;
  @extend .small-text;
  padding: 3em;
}

h1 {
  @extend .xxx-large-text;
}

.container-box {
  background-color: $white;
  border-radius: $sborder-radius-m;
  margin: 1.4em 0 0;
  padding: 0.5em 2.5em 2em;

  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  h1 {
    margin: 1.5em 0;
  }

  h3 {
    margin-top: 2.5em;
  }

  p {
    font-size: 94%;
    line-height: 1.6em;
    text-align: left;
    color: $forest;
    margin-bottom: 1.5em;
  }

  ul {
    padding: 0;
  }

  li {
    color: $forest;
    font-size: 94%;
    list-style-type: disc;
    line-height: 1.6em;
    margin-bottom: 1em;
  }
}

.text-wrapper {
  white-space: nowrap;
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pac-container:after{
  content:none !important;
}

.gmnoprint, .gm-fullscreen-control, .gm-style-cc, .gm-style a[href^="https://maps.google.com/maps"]{
  display: none !important;
}

// Styles for page content.
.page-content-layout-white {
  height: auto;
  border-radius: 8px;
  background: $white;
}

.hr-divider-gray {
  hr {
    background: $forest_20;
  }
}

%fund-dashboard-mini-card{
  padding : 2rem;
  min-height: 38.125rem;
  min-width: 21.25rem;

  .p-card-body {
    padding: 0;
  }

  .p-card-content {
    padding: 0;
  }
}

%checkbox-user {
  .p-checkbox .p-checkbox-box{
    width: 24px !important;
    height: 24px !important;
    border-radius: 4px;
    border: 2px solid;
    border-color: $forest_40 !important;
    background: $white !important;

    .p-checkbox-icon {
      color: $forest;
      font-weight: 1000;
      font-size: 11px;
    }
  }

  .p-checkbox{
    width: 24px!important;
    height: 24px!important;
  }

  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus{
    box-shadow: none;
  }
}

.round-label {
  width: fit-content;
  height: 1.5rem;
  padding: 0.063rem 0.6rem;
  border-radius: 1.25rem;
  background: $forest_20;
  line-height: 1.313rem;

  .pi-check{
    font-size: 0.8rem;
    font-weight: 800;

    &:before {
      color: $forest
    }
  }

  .p-name{
    color: $forest;
    font-weight: 400;
    font-size: 0.875rem;
  }
}

.count-selection-container {
  display: grid;
  grid-template-columns: auto auto auto;

  .count-status {
    height: 1.188rem;
    cursor: pointer;
  }

  .total-count {
    min-width: 4.75rem;
    font-weight: 400;
    text-transform: lowercase;
  }

  .selected-count {
    min-width: 2.5rem;
    padding-right: 0.25rem;
    text-align: right;
  }

  .selected-label {
    min-width: 4.188rem;
  }

  .status-inactive {
    opacity: 0.5;
  }
}

//Fund pages
.assets-table-container .no-data-msg,
.data-quality-asset-list .no-data-msg,
.performance-asset-list .no-data-msg,
.performance-asset-list .performance-asset-list-wrapper .no-data-msg{
  .p-datatable-tbody > tr{
    background-color: transparent;
    cursor: auto !important;
    &:not(.p-highlight):hover{
      background: transparent !important;
    }
  }
  .p-datatable-tbody > tr > td{
    padding: 0 !important;
    cursor: auto !important;
    &:hover{
      background: transparent !important;
    }
  }
  .no-result-msg{
    background: $white;
    .no-result-text{
      color: $forest;
    }
  }
}
//Fund pages

%dialog-header{
  .p-dialog-header {
    background: $forest_10;
    height: 3.63rem;
    padding: 1rem;
    color: $white;
    border-top-right-radius: 0.5rem;
    border-top-left-radius: 0.5rem;

    .p-dialog-header-icon:enabled:hover {
      color: $white;
      background: transparent;
    }

    .p-dialog-header-icon:focus {
      box-shadow: none;
    }

    .p-dialog-title {
      @extend .ex-large-text;
    }

    .p-dialog-header-icons {
      align-self: center;

      .p-dialog-header-close {
        color: $white;
      }

      .pi-times:before {
        font-size: 1.125rem;
      }
    }
  }

}

.p-overflow-hidden {
  overflow: hidden !important;
}

.dropdown-overlay-panel-white {
  background: $white;
  mix-blend-mode: normal!important;

  .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover{
    background: $forest_10 !important;
  }

  .p-highlight{
    background: $forest_10 !important;
  }

  .p-dropdown-item{
    font-size: 0.875rem;
    font-weight: 400;
  }

  .dropdown-item-2 {
    font-size: 0.875rem;
    font-weight: normal;
  }
}

.add-action-header-dropdown-panel {
  border: 0.063em solid $forest_20;
  border-radius:0.313rem !important;
  .p-dropdown-items-wrapper{
    .p-dropdown-items{
      .p-dropdown-item:first-child{
        border-top-left-radius: 0.313rem !important;
        border-top-right-radius: 0.313rem !important;
      }
    }
  }
}

.p-dropdown-panel-no-top-border {
  border: 0.063em solid $forest_20;
  border-radius: 0.313rem;
}

%dashboard-card-no-msg-table{
  padding: 1.5rem 1.5rem 1.5rem 0.8rem;
  margin-top: 1rem;
  background: $white;

  .no-result-text {
    color: $forest;
    font-size: 0.875rem;
    padding: 0;
  }
}

%epc-eps{
  label {
    font-weight: 400;
    font-size: 0.875rem;
  }

  span {
    font-weight: 500;
    font-size: 1.625rem;
  }

  .coverage {
    display: grid;
    border-bottom:1px solid rgba(37, 80, 86, 0.2);
    padding-bottom: 0.5rem;
  }

  .c-grey {
    color: $forest_80;
  }

  .valid-scores {
    padding-bottom: 0.7rem;
    border-bottom: 1px solid $forest_20;

    .grid {
      padding-top: 0.6rem;

      .p-datatable-tbody > tr > td {
        border: none;
        padding: 0;
      }

      .p-datatable-thead {
        display: none;
      }
    }
  }
}

%no-msg-table{
  .no-data-msg{
    margin-top:2.5rem;

    .no-result-msg{
      margin:0 !important;
    }

    .p-datatable-thead{
      display: none;
    }
  }
}

%form-field-label-checkbox {
  .disabled-checkbox {
    cursor: default;
  }

  &.axis-checkbox {
    padding-left: 0.4rem;
    padding-top: 0.6rem;
    display: flex;
  }

  .p-checkbox {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.75rem;

    &:focus {
      box-shadow: none;
    }
  }

  .p-checkbox-box {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 0.25rem;

    &:focus {
      box-shadow: none;
    }

    &:hover {
      border-color: $forest_40;
    }
  }

  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    box-shadow: none;
    border-color: $forest_40;
  }

  .p-checkbox-box.p-highlight {
    background: $white;
    border-color: $forest_40;

    .p-checkbox-icon {
      color: $forest;
    }

    &:hover {
      background: $white;
      border-color: $forest_40;
    }
  }

  & > label {
    margin-left: 0;
  }
}

%nzc-model-common-styles {
  background: $white;
  border-radius: 0.5rem;

  .p-dialog-header {
    background: $forest_10;
    color: $forest;
    border-top-right-radius: 0.5rem;
    border-top-left-radius: 0.5rem;

    .p-dialog-header-close {
      &:focus {
        box-shadow: none;
      }
    }

    .share-model-name {
      word-break: break-all;
    }
  }
  .p-dialog-content {
    padding: 3.125rem 3.125rem 0 3.125rem;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  &.share-model-header{
    .p-dialog-header{
      .p-dialog-title{
        padding-right:18.75rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
%es-header{
  display: flex;
  flex-direction: column;
  margin-bottom: 4.188rem;
}

%es-sub-text{
  @extend .larger-text-400;
  line-height: 2.5rem;
}

.es-asset-table{
  @extend %checkbox-user;
  @extend .ex-large-text;

  .p-datatable-thead > tr > th{
    @extend .ex-large-text;
    background: transparent;
    color:$forest;
    border:none;
    padding:1rem 1.5rem;

    &:nth-child(1){
      padding-left: 2rem;
    }
  }

  .p-datatable-tbody > tr > td{
    padding:1.4rem 1.5rem;
    border-bottom: 0.063rem solid $forest_20;
    border-right: 0.063rem solid $forest_20;

    &:nth-child(1){
      padding-left: 2rem;
    }
    &:nth-child(4){
      border-right: none;
    }
    &:last-child{
      border-right: none;
    }
  }

  .p-datatable-tbody > tr {
    border-bottom: 0.063rem solid $forest_40;

    &:last-child td {
      border-bottom: none!important;
    }
  }

  td {
    &:nth-child(1), &:nth-child(2) {
      border-right: 0.063rem solid $forest_20;
    }
  }

  .p-datatable-tbody > tr:not(.p-highlight):hover,.p-datatable-tbody > tr.p-highlight{
    background: $forest_05 !important;
  }

  .pointer-none{
    pointer-events: none;
  }

  .disable-row {
    pointer-events: none;
    opacity: 0.6;
  }

  .p-datatable-tbody > tr.p-row-odd{
    color:$forest;
    background: $white;
    @extend .ex-large-text-light;
  }

  .p-datatable-tbody > tr{
    color:$forest;
    background: $white;
    @extend .ex-large-text-light;
  }

  .es-selection{
    width:6%;
  }

  .siera-assets{
    width:43%;

    .asset-name{
      @extend .ex-large-text;
    }
  }

  .es-assets{
    width:55%;
  }
}

.p-checkbox-disabled {
  opacity: 0.5;
}

.pointer-cursor {
  cursor: pointer;
}

.default-cursor {
  cursor: default;
}

// styles for slide-in component

.sidebar-title,
.accordion-title {
  @extend .large-text-2;
  color: $forest;
}

.custom-filter-sidebar {
  width: 23.75rem !important;
  font-family: $font-family-base;

  .p-sidebar-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: var(--brand-colours-s-teal-10, rgba(37, 80, 86, 0.10));

    .filter-header-title {
      color: $forest;
      @extend .large-text-2;
      line-height: normal;
    }

    .p-sidebar-close {
      width: 2rem;
      height: 2rem;

      &:focus {
        box-shadow: none;
      }
    }
  }

  .p-sidebar-content {
    display: flex;
    justify-content: center;

    .filter-content-container {
      width: 20rem;
      margin-top: 1.875rem;
      display: flex;
      flex-direction: column;
      gap: 1.3rem;

      &__dropdown,
      &__calendar {
        display: flex;
        flex-direction: column;
        gap: 0.313rem;

        input {
          color: $forest;
          padding: 0.62rem 0.62rem 0.62rem 0.94rem;

          &::placeholder {
            color: $forest_80;
          }
        }

        .p-placeholder {
          color: $forest_80;
        }

        &--label {
          display: flex;
          flex-direction: row;
          gap: 0.375rem;
          align-items: center;

          span {
            color: $forest_80;
            @extend .medium-text-3;
            line-height: normal;
          }

          & > span > .icon {
            width: 0.938rem;
            height: 0.938rem;
            cursor: pointer;
          }
        }

        .p-dropdown {
          border-color: rgba(37, 80, 86, 0.4);
        }
      }

      &__apply-cancel-button-container {
        display: flex;
        flex-direction: row;
        gap: 0.938rem;

        &--apply, &--cancel {
          height: 2.313rem;
          padding: 0 0.938rem;
          justify-content: center;
          align-items: center;
          gap: 0.625rem;
          flex-shrink: 0;
          border-radius: $border-radius-5;

          &:focus {
            box-shadow: none;
          }
        }

        &--apply {
          background: $forest;
          border: none;
        }

        &--cancel {
          border: 0.063rem solid $forest;
          background: transparent;
          color: $forest;
        }
      }
    }
  }
}

// newly added className in the primeReact DataTable Header, and it's effecting alignment for all the table headers.
.p-datatable .p-datatable-thead > tr > th {
  .p-column-header-content {
    display: block;
  }
}

.export-checkbox {
  align-items: center;
  display: flex;
  margin-bottom: 1rem;

  @extend %form-field-label-checkbox;
}

//Global blue outline is removed for table
.p-datatable.p-datatable-selectable .p-datatable-tbody > tr.p-selectable-row:focus{
  outline: none;
  box-shadow: none;
}

//Global blue outline is removed for dialog close icon
.p-dialog-header .p-dialog-header-icon:focus{
  outline: none!important;
  box-shadow: none!important;
}

.p-datatable.p-datatable-selectable .p-datatable-tbody > tr.p-selectable-row:not(.p-highlight):not(.p-datatable-emptymessage):hover {
  background: $forest_05 !important;
}

%siera-plus-logo {
  position: relative;
  width: 4.125rem;
  height: 3.563rem;
  right: 0;
  left: 0;
  top: 40%;
  bottom: 0;
  margin: auto;

  .outer-stroke {
    stroke-width: 0.125rem;
    stroke-dasharray: 12.5rem;
    stroke-dashoffset: 25rem;
    animation: logo-icon-loader 2s linear infinite;
  }

  .inner-stroke {
    stroke-width: 0.125rem;
    stroke-dasharray: 6.25rem;
    stroke-dashoffset: 12.5rem;
    animation: logo-icon-loader 2s linear infinite;
  }

  @keyframes logo-icon-loader {
    100% {
      stroke-dashoffset: 0;
    }
  }
}

.meter-data,
.accordion-content {
  &__row {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 0.25rem 0;
  }

  .comment {
    &__value {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
    }
  }
}

.request-confirmation {
  margin-left: 1rem;
  color: $forest;
}

.nav-link-active{
  @extend .medium-text-3;
  text-decoration: underline;
  color:$blue;
}

.info-button {
  height: 1.125rem;
}

.error {
  color: $red;
}

.error-field {
  .p-inputtext:enabled, .p-dropdown, .p-multiselect  {
    /* Red border */
    border: 0.06rem solid $red;
    box-shadow: none;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;

    &:focus,
    &:hover {
      border: 0.06rem solid $red;
      box-shadow: none;
    }
    &:not(.p-disabled) {

      &:hover {
        border-color: $red;
      }
  
      &.p-focus {
        border-color: $red;
        box-shadow: none;
      }
    }
  }
}

.p-error-border {
  border: 1px solid $red !important;
  border-radius: $sborder-radius-6;

  &.p-inputtext {
    border-radius: $sborder-radius-6;
    border: 1px solid $red !important;
  }

  .p-button {
    border: none !important;
  }

  .p-inputtext {
    border: none !important;

    &:enabled:hover {
      border: none;
    }
  }
}

.p-warning-border {
  border: 1px solid $nature !important;
  border-radius: $sborder-radius-6;

  &.p-inputtext {
    border-radius: $sborder-radius-6;
    border: 1px solid $nature !important;
  }
}

.add-button{
  background: $forest;
  border: none;
  color: $white;
  box-shadow: none;
  width: 8rem;
  &:enabled:hover,
  &:enabled:focus,
  &:enabled:active {
    background: $forest;
    border: none;
    color: $white;
    box-shadow: none
  }
}

.cancel-button {
  background: none;
  color: $forest;
  border: 0.063rem solid $forest;
  box-shadow: none;
  &:enabled:hover,
  &:enabled:focus,
  &:enabled:active {
    background: none;
    box-shadow: none;
    color: $forest;
    border: 0.063rem solid $forest;
  }
}

.text-link-blue {
  color: $blue !important;
  text-decoration: underline;
}

.green-text {
  color: $green
}

%product-image {
  width: toRem(39px);
  height: toRem(39px);
  background-color: $forest_10;
  border-radius: $sborder-radius-m;
}

%dot {
  height: 1.25em;
  width: 1.25em;
  border-radius: 50%;
}

.cursor {
  cursor: pointer;
}

body:has(.p-sidebar-mask) {
  overflow: hidden;
}
 // To make the up and down arrow disappear in type='number'
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}